import Antdv from './antdv';
import ElementPlus from './element-plus';
import VueQuill from './vue-quill';
import katex from 'katex';
import 'katex/dist/katex.min.css'


export default class {
  static install(app) {
    katex.expression = true;

    window.katex = katex;
    app.use(Antdv);
    app.use(ElementPlus);
    app.use(VueQuill);
  }
}