import {
  createRouter,
  createWebHashHistory
} from 'vue-router';


import UserPaperTypeEnum from 'config/enum/userpapertype';
import PermissionRole from 'config/permission-role';

const router = createRouter({
  base: process.env.VUE_APP_BASE_PATH || '',
  history: createWebHashHistory(),
  routes: [{
      path: '/index',
      name: 'home',
      meta: {
        title: '首页',
      },
      component: () => import('../views/index')
    },
    {
      path: '/users',
      component: () => import('component/layout/layout'),
      children: [{
          path: '/users',
          name: 'userList',
          component: () => import('../views/users/list')
        },
        {
          path: '/user/:id',
          name: 'userDetail',
          component: () => import('../views/users/user-detail')
        }
      ]
    },
    {
      component: () => import('component/layout/layout'),
      children: [{
          path: '/examination/information',
          name: 'ExaminationInformation',
          meta: {
            title: '考试信息',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/examination/information')
        },
        {
          path: '/examination/rating-list',
          name: 'ExaminationRatingList',
          meta: {
            title: '待评试卷',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/examination/rating-list')
        },
        {
          path: '/examination',
          name: 'ExaminationCreate',
          meta: {
            title: '组卷管理',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/examination/index')
        },
        {
          path: '/qlib',
          name: 'qLib',
          meta: {
            title: '题库管理',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/qlib')
        },
        {
          path: '/auxiliary/subject',
          name: 'AuxiliarySubject',
          meta: {
            title: '学科类别设置',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/auxiliary/subject')
        },
        {
          path: '/auxiliary/examination-category',
          name: 'AuxiliaryExaminationCategory',
          meta: {
            title: '考试类型设置',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/auxiliary/examination-category')
        },
        {
          path: '/auxiliary/rating-teacher',
          name: 'AuxiliaryRatingTeacher',
          meta: {
            title: '评分教师设置',
            roleIds: [PermissionRole.ADMIN]
          },
          component: () => import('../views/auxiliary/rating-teacher')
        }
      ]
    },
    {
      component: () => import('component/layout/layout'),
      children: [{
          path: '/stu/my/examination',
          name: 'StuMyExamination',
          meta: {
            title: '我的考试',
            roleIds: [PermissionRole.STUDENT]
          },
          component: () => import('../views/stu/my-examination')
        },
        {
          path: '/stu/exam/:testPaperId',
          name: 'StuExam',
          meta: {
            title: '我的考试',
            paperType: UserPaperTypeEnum.NORMAL,
            roleIds: [PermissionRole.STUDENT]
          },
          component: () => import('../views/stu/exam')
        },
        {
          path: '/stu/re-exam/:testPaperId',
          name: 'StuReExam',
          meta: {
            title: '我的考试',
            paperType: UserPaperTypeEnum.RE_EXAMINATION,
            roleIds: [PermissionRole.STUDENT]
          },

          component: () => import('../views/stu/exam')
        }
      ]
    },
    {
      component: () => import('component/layout/layout'),
      children: [{
        path: '/rating',
        name: 'Rating',
        meta: {
          title: '评分首页',
          roleIds: [PermissionRole.TEACHER]
        },
        component: () => import('../views/rating')
      }]
    },
    {
      component: () => import('component/layout/layout'),
      children: [{
        path: '/preview/:userTestPaperId',
        name: 'ExamPreview',
        meta: {
          title: '考试详情',
          roleIds: [PermissionRole.STUDENT, PermissionRole.ADMIN],
          hideSlider: true
        },
        component: () => import('../views/stu/preview')
      }]
    },
    {
      path: "/reset/password",
      name: "resetPassword",
      meta: {
        title: '密码重置',
        noAuth: true,
        // hideSlider: ture
      },
      component: () => import('../views/login/reset-password')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: '登录',
        noAuth: true
      },
      component: () => import('../views/login')
    }
  ]
});

export default router;