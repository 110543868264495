import Dayjs from 'dayjs';
export default {
  name: 'DateRangePicker',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dateRange: [],
      presets: [{
        label: '近一周',
        value: [Dayjs().add(-7, 'd'), Dayjs()]
      }, {
        label: '近半月',
        value: [Dayjs().add(-15, 'd'), Dayjs()]
      }, {
        label: '近一月',
        value: [Dayjs().add(-30, 'd'), Dayjs()]
      }, {
        label: '近三年',
        value: [Dayjs().add(-90, 'd'), Dayjs()]
      }]
    };
  },
  created() {
    this.setDateRange();
  },
  watch: {
    value() {
      this.setDateRange();
    }
  },
  methods: {
    setDateRange() {
      if (Array.isArray(this.value)) {
        const [start, end] = this.value;
        if (Dayjs.isDayjs(start) && Dayjs.isDayjs(end)) {
          this.dateRange = [start.clone(), end.clone()];
          return;
        }
      }
      this.dateRange = [];
    },
    onChange() {
      this.$emit('update:value', this.dateRange);
    }
  }
};