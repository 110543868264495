import {
  createApp
} from 'vue'
import Plugins from './plugins'
import Components from './components'
import App from './App.vue'
import Router from './router'
import Store from './store'
import './permission';
const app = createApp(App);


app.use(Router)
  .use(Store)
  .use(Plugins)
  .use(Components)
  .mount('#app');


const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}