import {
  Quill,
  QuillEditor
} from '@vueup/vue-quill';
// window.Quill = Quill;
import ImageResize from 'quill-image-resize-module';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default class VueQuill {
  static install(app) {
    app.component('QuillEditor', QuillEditor);
    Quill.register('modules/imageResize', ImageResize);
  }
}