import LoginApi from 'api/login';
import Router from '../../router';
export default {
  namespaced: true,
  state: () => ({
    info: null
  }),
  mutations: {
    setUserInfo(state, info) {
      state.info = info;
    },
    loginOut(state) {
      state.info = null;
      //请求接口
      LoginApi.loginOut();
      Router.push({
        name: 'login'
      })
    }
  }
}