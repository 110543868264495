import Axios from "axios";
import Store from '../store'
import Router from '../router'

const {
  VUE_APP_BASE_PATH
} = process.env;

const instance = Axios.create({
  baseURL: VUE_APP_BASE_PATH, // api 的 SERVER_HOST
  // withCredentials: true,
  crossDomain: true,
  timeout: 60000 * 5 // request timeout
});
// request interceptor
instance.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};
    config.headers['Request-Hash'] = encodeURIComponent(window.location.hash)
    // config.params = config.params || {};
    // config.params.v = Math.random() * 10000;
    return config;
  },
  (error) => {
    // Do something with request error
    console.error(error); // for debug
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const {
      config
    } = response;
    //如果返回文件流，返回整个response对象
    if (config.responseType === "blob") return response;
    return response.data;
  },
  (error) => {
    if ((error.message || '').indexOf('timeout') > -1) {
      if (error.config.responseType === 'blob') {
        return {
          status: 500,
          errorMsg: 'timeout'
        }
      }
    }
    switch (error.response.status) {
      case 401: {
        Store.commit('user/loginOut');
        break;
      }
      default:
        break;
    }
    return {
      code: 0,
      message: (error.response.data || {}).message || "服务异常，请稍后重试"
    };
  }
);
export default instance;