import BaseEnum from './base';
export default class UserPaperType extends BaseEnum {
  /**
   * 获取数据
   */
  static get data() {
    return [{
      "value": 1,
      "name": "NORMAL",
      "description": "首次"
    }, {
      "value": 2,
      "name": "RE_EXAMINATION",
      "description": "复考"
    }];
  }
  /**
   * 首次
   */
  static get NORMAL() {
    return 1;
  }
  /**
   * 复考
   */
  static get RE_EXAMINATION() {
    return 2;
  }
}