import Dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'ant-design-vue/es/locale/zh_CN';
Dayjs.locale('zh-cn');
import 'asset/css/theme.less';
export default {
  name: 'App',
  data() {
    return {
      locale,
      theme: {
        token: {
          // colorPrimary: '#1677ff',
          // colorLink: '#1677ff',
        }
      }
    };
  }
};

// const debounce = (fn, delay) => {
//   let timer = null;
//   return function () {
//     let context = this;
//     let args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   }
// }

// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 16);
//     super(callback);
//   }
// }