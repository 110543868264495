import BaseApi from './base'
export default class PassportApi extends BaseApi {

  /**
   * 获取当前登录的用户信息
   *
   * @static
   * @return {*} 
   * @memberof PassportApi
   */
  static getUser() {
    return this.request({
      url: '/api/passport/getUser',
    });
  }


  /**
   * 密码修改
   * @returns 
   */
  static updatUserPassword({
    password,
    newPassword
  }) {
    return this.request({
      url: '/api/passport/updatUserPassword',
      method: 'POST',
      data: {
        password,
        newPassword
      }
    });
  }


  /**
   * 发送重置密码邮件
   * @param {*} email 
   * @returns 
   */
  static sendResetPasswordEmail(email) {
    return this.request({
      url: '/api/passport/sendResetPasswordEmail',
      params: {
        email
      }
    });
  }


  /**
   * 验证重置密码Token
   * @param {*} token 
   * @returns 
   */
  static checkResetPasswordToken(token) {
    return this.request({
      url: '/api/passport/checkResetPasswordToken',
      params: {
        token
      }
    });
  }

  /**
   * 重置密码
   * @param {*} token 
   * @param {*} password 
   * @returns 
   */
  static resetPassword(token, password) {
    return this.request({
      url: '/api/passport/resetPassword',
      method: 'POST',
      data: {
        token,
        password
      }
    });
  }
}