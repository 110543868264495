import Router from './router';
import Store from './store';
import PassportApi from 'api/passport';
// import Cookie from 'js-cookie'



Router.beforeEach(async (to, from, next) => {
  const meta = to.meta || {};

  if (to.fullPath === '/') {
    next({
      path: '/index'
    });
    return;
  }

  if (meta.noAuth) {
    next();
    return;
  }
  const titleEnd = process.env.NODE_ENV === 'production' ? '北京师范大学系统科学学院考试系统' : 'Examination System';
  document.title = meta.title ? `${meta.title}-${titleEnd}` : titleEnd;

  if (!Store.state.user.info) {
    //获取用户信息
    const {
      code,
      data
    } = await PassportApi.getUser();

    if (code === 1 && data) {
      Store.commit('user/setUserInfo', data);
    }
  }
  //没登录时，跳转到登录页
  const {
    userInfo
  } = Store.getters;


  if (!userInfo) {
    next({
      name: 'login'
    });
    return;
  }

  //判断是否有权限进入当前页面

  if (Array.isArray(meta.roleIds) && userInfo.roleIds.filter(roleId => meta.roleIds.includes(roleId)).length === 0) {
    //跳转到首页
    next({
      name: 'home'
    });
    return;
  }

  //获取用户信息
  next();
})