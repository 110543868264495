export default {
  name: 'Breadcrumb',
  props: {
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    breadcrumbText() {
      const {
        title: pageTitle
      } = this.$route.meta || {};
      return this.title || pageTitle;
    }
  }
};