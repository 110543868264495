import {
  Button,
  Layout,
  ConfigProvider,
  Menu,
  Dropdown,
  Row,
  Col,
  Switch,
  Modal,
  Input,
  InputNumber,
  Form,
  Tooltip,
  Spin,
  Drawer,
  Table,
  Select,
  DatePicker,
  List,
  Checkbox,
  Radio,
  RadioGroup,
  Upload,
  Tabs,
  Popconfirm,
  Popover,
  Tag,
  message,
  Result,
  Image,
  Statistic,
  Affix,
  Divider
} from "ant-design-vue";
export default class Antdv {
  static install(app) {
    [
      Button,
      Layout,
      ConfigProvider,
      Menu,
      Dropdown,
      Row,
      Col,
      Switch,
      Modal,
      Input,
      InputNumber,
      Form,
      Tooltip,
      Spin,
      Drawer,
      Table,
      Select,
      DatePicker,
      List,
      Checkbox,
      Radio,
      RadioGroup,
      Upload,
      Tabs,
      Popconfirm,
      Popover,
      Tag,
      Result,
      Image,
      Statistic,
      Affix,
      Divider
    ].forEach((component) => {
      app.use(component);
    });

    message.config({
      top: `60px`
    })
    app.config.globalProperties.$message = message;
    app.config.globalProperties.$modal = Modal;


  }
}