import {
  ElBacktop
} from "element-plus";
export default class Antdv {
  static install(app) {
    [
      ElBacktop
    ].forEach((component) => {
      app.use(component);
    });
  }
}