export default class BaseEnum {
  /**
   * 获取description
   * @param {*} value 
   * @returns 
   */
  static getDescription(value) {
    const findItem = this.data.find(item => item.value === value);
    if (!findItem) return null;
    return findItem.description;
  }

  static getName(value) {
    const findItem = this.data.find(item => item.value === value);
    if (!findItem) return null;
    return findItem.name;
  }
}