import BaseApi from './base'
export default class LoginApi extends BaseApi {
  /**
   * 获取验证码图片
   *
   * @static
   * @memberof LoginApi
   */
  static getCaptchaCodeImgBase64() {
    return this.request({
      url: '/api/login/getCaptchaCodeImgBase64',
    });
  }


  /**
   * 登录函数
   *
   * @param loginId 登录ID
   * @param password 密码
   * @param captchaCode 验证码
   * @param key 密钥
   * @param remembe7 是否7天免登录
   * @param isCC 是否成果系统
   * @returns 无返回值
   */
  static login({
    loginId,
    password,
    captchaCode,
    key,
    remembe7
  }) {
    return this.request({
      url: '/api/login',
      method: 'post',
      data: {
        loginId,
        password,
        captchaCode,
        key,
        remembe7
      },
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
  }


  static loginOut() {
    return this.request({
      url: '/api/login/out'
    });
  }
}